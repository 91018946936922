* {
  box-sizing: border-box;
}

body, html {
  font-family: 'Courier New', Courier, monospace;
  padding: 0;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.container {
  background-color: #1E2738;
}

.board {
  transform-origin: top left;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}
  
.messages {
  overflow-y: scroll;
  background-color: #303A4E;
  padding: 20px;
  color: white;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .board {
    width: 100%;
    max-width: 600px;
  }

  .messages {
    width: 100%;
    max-width: 600px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app {
    display: flex;
    width: 1200px;
  }
  
  .board {
    position: relative;
    width: 600px;
  }
  
  .messages {
    width: 600px;
    height: 600px;
    flex: 1 1 600px;
  }
}